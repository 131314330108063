import ApplicationController from "@/controllers/application_controller";
export default class extends ApplicationController {
  initialize() {
    console.log("Answer::Button::ComponentController[index]", this.element);
  }
  connect() {
    console.log("Answer::Button::ComponentController[connect]", this.element);
  }
  handleAnswer(event) {
    event.preventDefault();
    this.answerController.getAnswerData(event.target);
    this.answerController.handleAnswer();
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
}
