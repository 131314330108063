import ApplicationController from "@/controllers/application_controller";
export default class extends ApplicationController {
  initialize() {
    console.log("offer_list initialize");
  }
  connect() {
    console.log("offer_list connect");
  }
  log() {
    console.log("offer_list here");
  }
}
