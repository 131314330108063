var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export class ChainParamConditionModule {
  constructor(conditions, params, nodes) {
    this.conditions = conditions;
    this.params = params;
    this.nodes = nodes;
    this.nodesWrapper = nodes[0].parentElement;
    this.locationData = JSON.parse(document.body.dataset.sessionLocation || {});
    console.log("ParamConditionModule::[conditions]", this.nodes);
    console.log("ParamConditionModule::[checkConditions]", this.params);
    console.log("ParamConditionModule::[nodes]", this.nodes);
    console.log("ChainParamConditionModule:locationData", this.locationData);
  }
  checkConditions() {
    return __async(this, null, function* () {
      const items = yield this.collectConditionItems(this.conditions, this.params);
      console.log("cond-items", items);
      const deleteConditions = items.filter((cond) => cond.hide === true);
      const moveConditions = items.filter((cond) => cond.hide !== true && cond.position).sort((a, b) => a.position - b.position);
      deleteConditions.map((cond) => {
        const condItem = this.nodes.find((i) => i.dataset.id === cond.id);
        this.nodes = this.nodes.filter((i) => i.dataset.id !== cond.id);
        condItem.remove();
      });
      this.nodes = this.recalculatePositions(this.nodes);
      moveConditions.map((cond) => this.handleChangePosition(this.nodes, cond));
      this.nodes.sort((a, b) => a.dataset.position - b.dataset.position).forEach((e) => this.nodesWrapper.appendChild(e));
    });
  }
  recalculatePositions(arr) {
    return arr.map((item, idx) => {
      item.dataset.position = idx + 1;
      return item;
    });
  }
  handleChangePosition(arr, cond) {
    const element = arr.find((i) => i.dataset.id === cond.id);
    if (!element)
      return arr;
    const newPos = cond.position;
    arr = arr.map((i) => {
      if (element.dataset.position < newPos) {
        if (i.dataset.position > element.dataset.position && i.dataset.position <= newPos)
          i.dataset.position -= 1;
      } else if (element.dataset.position > newPos) {
        if (i.dataset.position < element.dataset.position && i.dataset.position >= newPos)
          i.dataset.position += 1;
      }
      return i;
    });
    element.dataset.position = newPos;
    return arr;
  }
  collectConditionItems(conditions, params) {
    return __async(this, null, function* () {
      function mapAsync(array, callbackfn) {
        return Promise.all(array.map(callbackfn));
      }
      function filterAsync(array, callbackfn) {
        return mapAsync(array, callbackfn).then((filterMap) => {
          return array.filter((value, index) => filterMap[index]);
        });
      }
      const removeDuplicatesByKey = (collection, keys) => {
        console.log("RD::COLLECTION", collection);
        return collection.filter((item, idx, arr) => {
          return idx === arr.findIndex((el) => {
            const isDuplicate = keys.reduce((acc, key) => {
              acc && (acc = el[key] === item[key]);
              return acc;
            }, true);
            return isDuplicate;
          });
        });
      };
      let result_items = [];
      console.log("condition?beforeFilter", conditions);
      const isMatchedCondition = (cond) => __async(this, null, function* () {
        return yield this.conditionMatched(cond, params);
      });
      const matched = yield filterAsync(conditions, isMatchedCondition);
      console.log("MATCHED:::", matched);
      result_items = yield matched.map((cond) => cond.items);
      console.log("MATCHED::: result_items", result_items);
      return yield removeDuplicatesByKey(result_items.flat(), ["id", "hide"]);
    });
  }
  conditionMatched(condition, params) {
    return __async(this, null, function* () {
      let matched = false;
      if (condition.checkType === "zip") {
        matched = yield this.checkZip(condition.zipListId, this.locationData.zip);
      } else {
        matched = params[condition.name];
        if (condition.value)
          matched = params[condition.name] === condition.value;
      }
      console.log("condition?match3", [condition, matched]);
      return matched;
    });
  }
  checkZip(zipListId, clientZip) {
    return __async(this, null, function* () {
      const url = `/api/v1/zip_lists/${zipListId}/check?zip=${clientZip}`;
      return yield fetch(url).then(function(response) {
        return response.json();
      }).then(function(data) {
        console.log("DATA:::RES", data);
        return data.valid;
      });
    });
  }
}
