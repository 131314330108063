var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
import ApplicationController from "@/controllers/application_controller";
const trackerModuleInst = new EventTrackerModule(ahoy);
export default class extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "pixelModule");
  }
  initialize() {
    this.pixelModule = trackerModuleInst;
    console.log("[NotificationController::initialize] this.pixel", this.pixelModule);
  }
  connect() {
    super.connect();
    console.log("[NotificationController::initialize] connect");
  }
}
